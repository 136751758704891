import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import { ThemeProvider } from "@emotion/react";
import { legionTheme } from "@legion-ui/core";
import ColorsCard from "../../../components/CardColor/CardColor.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Getting Colors Right`}</h2>
    <p>{`Colors in UI design can speak in ways that are every bit as powerful as copy. They not only affect how your design looks, but can go as far as to elicit emotion and reflect the personality of your brand. Colors also play an important role in how your designs “look & feel”, and are the first impression.`}</p>
    <p>{`While color can be subjective, it’s clear that good colors can attact and convert users. Bad colors, on the other hand, can turn away users and even make designs inaccessible and unusable.`}</p>
    <div style={{
      "heigh": "1px",
      "marginBottom": "5rem"
    }} />
    <h2>{`Defining your color palette`}</h2>
    <p>{`Defining the right color palette for a project is one of the most important steps in the design process, but it can be quite difficult to get right. Hues affect the style and mood of the design, create the “look & feel” and are highly memorable. Our design system leverages a purposeful set of color styles as the perfect starting point for any brand or project.`}</p>
    <div style={{
      "heigh": "1px",
      "marginBottom": "5rem"
    }} />
    <h2>{`Primary Colors`}</h2>
    <p>{`The primary color is your “brand” color, and is used across all interactive elements such as buttons, links,
inputs, etc. This color can define the overall feel and can elicit emotion.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="primary25" mdxType="ColorsCard" />
  <ColorsCard variant="primary50" mdxType="ColorsCard" />
  <ColorsCard variant="primary100" mdxType="ColorsCard" />
  <ColorsCard variant="primary200" mdxType="ColorsCard" />
  <ColorsCard variant="primary300" mdxType="ColorsCard" />
  <ColorsCard variant="primary400" mdxType="ColorsCard" />
  <ColorsCard variant="primary500" mdxType="ColorsCard" />
  <ColorsCard variant="primary600" mdxType="ColorsCard" />
  <ColorsCard variant="primary700" mdxType="ColorsCard" />
  <ColorsCard variant="primary800" mdxType="ColorsCard" />
  <ColorsCard variant="primary900" mdxType="ColorsCard" />
    </div>
    <h3><strong>{`Usage Example`}</strong></h3>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : Common Component Colors" caption="Use primary as common UI Components colors" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/color-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don’t : Common Content Colors" caption="Use primary as content colors on screen" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/color-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div style={{
      "heigh": "1px",
      "marginBottom": "5rem"
    }} />
    <h2>{`Secondary Palette`}</h2>
    <p>{`The secondary colors is color highlight or complement the primary color.
These are to be used sparingly to make the UI elements stand out. These colors are also usually defined in the brand guidelines.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="secondary25" mdxType="ColorsCard" />
  <ColorsCard variant="secondary50" mdxType="ColorsCard" />
  <ColorsCard variant="secondary100" mdxType="ColorsCard" />
  <ColorsCard variant="secondary200" mdxType="ColorsCard" />
  <ColorsCard variant="secondary300" mdxType="ColorsCard" />
  <ColorsCard variant="secondary400" mdxType="ColorsCard" />
  <ColorsCard variant="secondary500" mdxType="ColorsCard" />
  <ColorsCard variant="secondary600" mdxType="ColorsCard" />
  <ColorsCard variant="secondary700" mdxType="ColorsCard" />
  <ColorsCard variant="secondary800" mdxType="ColorsCard" />
  <ColorsCard variant="secondary900" mdxType="ColorsCard" />
    </div>
    <h2>{`Tertiary Palette`}</h2>
    <p>{`The tertiary color is a neutral color and is the foundation of the color system.
Almost everything in UI design — text, form fields, backgrounds, dividers, outline card — are usually gray.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="tertiary25" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary50" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary100" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary200" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary300" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary400" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary500" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary600" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary700" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary800" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary900" mdxType="ColorsCard" />
    </div>
    <h3><strong>{`Usage Example`}</strong></h3>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : For Accent and Background" caption="Use the secondary as accent section or half component colors and use the tertiary  for background and content colors" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/color-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don’t : For component and Accent" caption="Use the primary as whole background colors, secondary and tertiary as common component or accent colors on screen" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/color-4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div style={{
      "heigh": "1px",
      "marginBottom": "5rem"
    }} />
    <h2>{`Accent Colors`}</h2>
    <p>{`The Accent Colors is emphasis different semantic states, used to provide visual feedback and/or warnings to users as they use your interface`}</p>
    <h3><strong>{`Success`}</strong></h3>
    <p>{`Success colors communicate a positive action, postive trend, or a successful confirmation. If you’re using green as your primary color, it can be helpful to introduce a different hue for your success green.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="success25" mdxType="ColorsCard" />
  <ColorsCard variant="success50" mdxType="ColorsCard" />
  <ColorsCard variant="success100" mdxType="ColorsCard" />
  <ColorsCard variant="success200" mdxType="ColorsCard" />
  <ColorsCard variant="success300" mdxType="ColorsCard" />
  <ColorsCard variant="success400" mdxType="ColorsCard" />
  <ColorsCard variant="success500" mdxType="ColorsCard" />
  <ColorsCard variant="success600" mdxType="ColorsCard" />
  <ColorsCard variant="success700" mdxType="ColorsCard" />
  <ColorsCard variant="success800" mdxType="ColorsCard" />
  <ColorsCard variant="success900" mdxType="ColorsCard" />
    </div>
    <h3><strong>{`Warning`}</strong></h3>
    <p>{`Warning colors can communicate that an action is potentially destructive or “on-hold”. These colors are commonly used in confirmations to grab the users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="warning25" mdxType="ColorsCard" />
  <ColorsCard variant="warning50" mdxType="ColorsCard" />
  <ColorsCard variant="warning100" mdxType="ColorsCard" />
  <ColorsCard variant="warning200" mdxType="ColorsCard" />
  <ColorsCard variant="warning300" mdxType="ColorsCard" />
  <ColorsCard variant="warning400" mdxType="ColorsCard" />
  <ColorsCard variant="warning500" mdxType="ColorsCard" />
  <ColorsCard variant="warning600" mdxType="ColorsCard" />
  <ColorsCard variant="warning700" mdxType="ColorsCard" />
  <ColorsCard variant="warning800" mdxType="ColorsCard" />
  <ColorsCard variant="warning900" mdxType="ColorsCard" />
    </div>
    <h3><strong>{`Information`}</strong></h3>
    <p>{`Infomation colors can communicate that an action is informative section or`}<br parentName="p"></br>{`
`}{`these colors are commonly used in awareness for users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="information25" mdxType="ColorsCard" />
  <ColorsCard variant="information50" mdxType="ColorsCard" />
  <ColorsCard variant="information100" mdxType="ColorsCard" />
  <ColorsCard variant="information200" mdxType="ColorsCard" />
  <ColorsCard variant="information300" mdxType="ColorsCard" />
  <ColorsCard variant="information400" mdxType="ColorsCard" />
  <ColorsCard variant="information500" mdxType="ColorsCard" />
  <ColorsCard variant="information600" mdxType="ColorsCard" />
  <ColorsCard variant="information700" mdxType="ColorsCard" />
  <ColorsCard variant="information800" mdxType="ColorsCard" />
  <ColorsCard variant="information900" mdxType="ColorsCard" />
    </div>
    <h3><strong>{`Error`}</strong></h3>
    <p>{`Error colors are used across error states and in “destructive” actions. They communicate a destructive/negative action, such as removing a user from your team.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="error25" mdxType="ColorsCard" />
  <ColorsCard variant="error50" mdxType="ColorsCard" />
  <ColorsCard variant="error100" mdxType="ColorsCard" />
  <ColorsCard variant="error200" mdxType="ColorsCard" />
  <ColorsCard variant="error300" mdxType="ColorsCard" />
  <ColorsCard variant="error400" mdxType="ColorsCard" />
  <ColorsCard variant="error500" mdxType="ColorsCard" />
  <ColorsCard variant="error600" mdxType="ColorsCard" />
  <ColorsCard variant="error700" mdxType="ColorsCard" />
  <ColorsCard variant="error800" mdxType="ColorsCard" />
  <ColorsCard variant="error900" mdxType="ColorsCard" />
    </div>
    <h3><strong>{`Usage Example`}</strong></h3>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : Use Accent in right context" caption="Carefully to use accent in part of component for right context or condition you want to inform" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/color-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don’t : Accent with wrong context" caption="Use accent in part of component on wrong context or condition make the users confuse" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/color-6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      